import i18n from "@/plugins/i18n";

export default {
  computed: {
    primaryButtonAdd() {
      return {
        icon: "mdi-plus",
        text: i18n.t("add"),
      };
    },
    primaryButtonClone() {
      return {
        icon: "mdi-file-multiple",
        text: i18n.t("clone"),
      };
    },
    primaryButtonEditDictionary() {
      return {
        icon: "mdi-content-save",
        text: i18n.t("save"),
      };
    },
    secondaryButton() {
      return {
        icon: "mdi-close",
        text: i18n.t("cancel"),
      };
    },
    primaryButtonEdit() {
      return {
        icon: "mdi-content-save",
        text: i18n.t("save"),
      };
    },
    InputDefinitionsDetail() {
      return {
        addFilter: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonAdd(),
            title: "addFilter",
            collectionAttr: "filters",
            alertAttr: "theFilter",
          },
          steps: [
            {
              title: i18n.t("indicateFilterName"),
              resume: [
                {
                  label: i18n.t("filterName"),
                  valuekey: "name",
                },
              ],
              component: "InputText",
              config: {
                label: i18n.t("filterName"),
                formAttribute: "name",
                validators: {
                  required: {msg: i18n.t("required")},
                  alreadyExists: {msg: i18n.t("nameAlreadyExists")},
                }
              },
            },
            {
              title: i18n.t("defineConditions"),
              component: "Conditionals",
              formAttribute: "filters",
              showCases: false,
            },
          ],
        },
        editFilter: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonEdit(),
            title: "editFilter",
            collectionAttr: "filters",
            alertAttr: "theFilter",
          },
          steps: [
            {
              title: i18n.t("indicateFilterName"),
              resume: [
                {
                  label: i18n.t("filterName"),
                  valuekey: "name",
                },
              ],
              component: "InputText",
              config: {
                label: i18n.t("filterName"),
                formAttribute: "name",
                validators: {
                  required: {msg: i18n.t("required")},
                  alreadyExists: {msg: i18n.t("nameAlreadyExists")},
                }
              },
            },
            {
              title: i18n.t("defineConditions"),
              component: "Conditionals",
              formAttribute: "filters",
              showCases: false,
            },
          ],
        },
      };
    },
    OutputDefinitionsDetail() {
      return {
        addColumnOut: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonAdd(),
            title: "addColumnOut",
            collectionAttr: "columns",
            alertAttr: "theColumn",
          },
          steps: [
            {
              title: i18n.t("defineCreateColumn"),
              resume: [
                {
                  label: i18n.t("name"),
                  valuekey: "alias",
                },
                {
                  label: i18n.t("showInFinalReport"),
                  valuekey: "displayColumn",
                },
                {
                  label: i18n.t("createColumn"),
                  valuekey: "withOrWithoutConditions",
                  translateValue: true,
                },
                {
                  label: i18n.t("decimalSeparator"),
                  valuekey: "decimalSeparator",
                  translateValue: true,
                },
                {
                  label: i18n.t("datePattern"),
                  valuekey: "datePattern",
                }
              ],
              component: "DefineColumn",
            },
            {
              title: i18n.t("defineHowSetValue"),
              defaultAlerts: [
                {condition: "key === 'dictionaryTransformation'", text: 'dictionaryFormWarning', type: 'warning', icon: 'mdi-alert'}
              ],
              showCases: true,
              formAttribute: "conditionals",
              dependentOnPreviousStep: true,
              dependentComponent: {
                withConditions: "Conditionals",
                withOutConditions: "AssignValues",
              },
              dependentTitle: {
                withConditions: i18n.t("defineCases"),
                withOutConditions: i18n.t("defineHowSetValue"),
              },
            },
          ],
        },
        editColumnOut: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonEdit(),
            title: "editColumnOut",
            collectionAttr: "columns",
            alertAttr: "theColumn",
          },
          steps: [
            {
              title: i18n.t("defineCreateColumn"),
              resume: [
                {
                  label: i18n.t("name"),
                  valuekey: "alias",
                },
                {
                  label: i18n.t("showInFinalReport"),
                  valuekey: "displayColumn",
                },
                {
                  label: i18n.t("createColumn"),
                  valuekey: "withOrWithoutConditions",
                  translateValue: true,
                },
                {
                  label: i18n.t("decimalSeparator"),
                  valuekey: "decimalSeparator",
                  translateValue: true,
                },
                {
                  label: i18n.t("datePattern"),
                  valuekey: "datePattern",
                }
              ],
              component: "DefineColumn",
            },
            {
              title: i18n.t("defineHowSetValue"),
              showCases: true,
              formAttribute: "conditionals",
              dependentOnPreviousStep: true,
              defaultAlerts: [
                {condition: "key === 'dictionaryTransformation'", text: 'dictionaryFormWarning', type: 'warning', icon: 'mdi-alert'}
              ],
              dependentComponent: {
                withConditions: "Conditionals",
                withOutConditions: "AssignValues",
              },
              dependentTitle: {
                withConditions: i18n.t("defineCases"),
                withOutConditions: i18n.t("defineHowSetValue"),
              },
            },
          ],
        },
        addCellValue: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonAdd(),
            title: "addCellValue",
            collectionAttr: "splitConfiguration",
            addAttributesToSubmit: ["alias", "position"],
            nestedAttr: "linesReferences",
            alertAttr: "theValue",
          },
          steps: [
            {
              title: i18n.t("defineCreateValue"),
              component: "withOrWithoutConditions",
              resume: [
                {
                  label: i18n.t("createValue"),
                  valuekey: "withOrWithoutConditions",
                  translateValue: true,
                },
              ],
            },
            {
              title: i18n.t("defineHowSetValue"),
              showCases: true,
              dependentOnPreviousStep: true,
              defaultAlerts: [
                {condition: "key === 'dictionaryTransformation'", text: 'dictionaryFormWarning', type: 'warning', icon: 'mdi-alert'}
              ],
              dependentComponent: {
                withConditions: "Conditionals",
                withOutConditions: "AssignValues",
              },
              dependentTitle: {
                withConditions: i18n.t("defineCases"),
                withOutConditions: i18n.t("defineHowSetValue"),
              },
            },
          ],
        },
        editCell: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonEdit(),
            title: "editCellValue",
            addAttributesToSubmit: ["alias", "position"],
            collectionAttr: "splitConfiguration",
            nestedAttr: "linesReferences",
            alertAttr: "theValue",
          },
          steps: [
            {
              title: i18n.t("defineCreateValue"),
              component: "withOrWithoutConditions",
              resume: [
                {
                  label: i18n.t("createValue"),
                  valuekey: "withOrWithoutConditions",
                  translateValue: true,
                },
              ],
            },
            {
              title: i18n.t("defineHowSetValue"),
              dependentOnPreviousStep: true,
              showCases: true,
              defaultAlerts: [
                {condition: "key === 'dictionaryTransformation'", text: 'dictionaryFormWarning', type: 'warning', icon: 'mdi-alert'}
              ],
              dependentComponent: {
                withConditions: "Conditionals",
                withOutConditions: "AssignValues",
              },
              dependentTitle: {
                withConditions: i18n.t("defineCases"),
                withOutConditions: i18n.t("defineHowSetValue"),
              },
            },
          ],
        },
      };
    },
    Dictionaries() {
      return {
        addDictionary: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonAdd(),
            title: i18n.t("addDictionary"),
            collectionAttr: "dictionaries",
            alertAttr: "theDictionary",
          },
          steps: [
            {
              title: i18n.t("indicateDictionaryName"),
              resume: [
                {
                  label: i18n.t("dictionaryName"),
                  valuekey: "name",
                },
              ],
              component: "InputText",
              config: {
                label: i18n.t("dictionaryName"),
                formAttribute: "name",
                validators: {
                  required: {msg: i18n.t("required")},
                  alreadyExists: {msg: i18n.t("nameAlreadyExists")},
                }
              },
            },
            {
              title: i18n.t("defineDictionaryKeys"),
              component: "DictionaryKeyValuesForm",
              resume: [
                {
                  label: "Key",
                  valuekey: "dictionaryKeys",
                  itemProp: "value"
                },
              ],
              config: {
                label: i18n.t("keyField"),
                formAttribute: "dictionaryKeys",
              },
            },
            {
              title: i18n.t("defineDictionaryValues"),
              component: "DictionaryKeyValuesForm",
              config: {
                label: i18n.t("valueField"),
                formAttribute: "dictionaryValues",
              },
            },
          ],
        },
        editDictionary: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonEditDictionary(),
            title: i18n.t("editDictionary"),
            collectionAttr: "dictionaries",
            alerts: ["alertEditDictionary"],
            alertAttr: "theDictionary",
          },
          steps: [
            {
              title: i18n.t("indicateDictionaryName"),
              resume: [
                {
                  label: i18n.t("dictionaryName"),
                  valuekey: "name",
                },
              ],
              component: "InputText",
              config: {
                label: i18n.t("dictionaryName"),
                formAttribute: "name",
                validators: {
                  required: {msg: i18n.t("required")},
                  alreadyExists: {msg: i18n.t("nameAlreadyExists")},
                }
              },
            },
            {
              title: i18n.t("defineDictionaryKeys"),
              component: "DictionaryKeyValuesForm",
              resume: [
                {
                  label: i18n.t("keyField"),
                  valuekey: "dictionaryKeys",
                  itemProp: "value"
                },
              ],
              config: {
                label: i18n.t("keyField"),
                formAttribute: "dictionaryKeys",
                staticAlerts: [
                  {
                    text: i18n.t("modifiedDictionaryAlert"),
                    type: "warning",
                    icon: "mdi-alert",
                  },
                ],
                deleteModal: {
                  title: i18n.t("deleteKeyTitle"),
                  question: i18n.t("deleteKeyQuestion"),
                  primaryButtonText: i18n.t("ok"),
                  secondaryButtonText: i18n.t("cancel"),
                  alerts: [
                    {
                      text: i18n.t("deleteKeyAlert"),
                      type: "warning",
                      icon: "mdi-alert",
                    },
                  ],
                },
              },
            },
            {
              title: i18n.t("defineDictionaryValues"),
              component: "DictionaryKeyValuesForm",
              config: {
                label: i18n.t("valueField"),
                formAttribute: "dictionaryValues",
                staticAlerts: [
                  {
                    text: i18n.t("modifiedDictionaryAlert"),
                    type: "warning",
                    icon: "mdi-alert",
                  },
                ],
                deleteModal: {
                  title: i18n.t("deleteValueTitle"),
                  question: i18n.t("deleteValueQuestion"),
                  primaryButtonText: i18n.t("ok"),
                  secondaryButtonText: i18n.t("cancel"),
                  alerts: [
                    {
                      text: i18n.t("deleteValueAlert"),
                      type: "warning",
                      icon: "mdi-alert",
                    },
                  ],
                },
              },
            },
          ],
        },
        cloneDictionary: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonClone(),
            title: i18n.t("cloneDictionary"),
            collectionAttr: "dictionaries",
            alertAttr: "theDictionary",
          },
          steps: [
            {
              title: i18n.t("indicateDictionaryName"),
              resume: [
                {
                  label: i18n.t("dictionaryName"),
                  valuekey: "name",
                },
              ],
              component: "InputText",
              config: {
                label: i18n.t("dictionaryName"),
                formAttribute: "name",
                validators: {
                  required: {msg: i18n.t("required")},
                  alreadyExists: {msg: i18n.t("nameAlreadyExists")},
                }
              },
            },
            {
              title: i18n.t("defineDictionaryKeys"),
              component: "DictionaryKeyValuesForm",
              resume: [
                {
                  label: "Key",
                  valuekey: "dictionaryKeys",
                  itemProp: "value"
                },
              ],
              config: {
                label: i18n.t("keyField"),
                formAttribute: "dictionaryKeys",
                deleteModal: {
                  title: i18n.t("deleteKeyTitle"),
                  question: i18n.t("deleteKeyQuestion"),
                  primaryButtonText: i18n.t("ok"),
                  secondaryButtonText: i18n.t("cancel"),
                  alerts: [
                    {
                      text: i18n.t("deleteKeyAlert"),
                      type: "warning",
                      icon: "mdi-alert",
                    },
                  ],
                },
              },
            },
            {
              title: i18n.t("defineDictionaryValues"),
              component: "DictionaryKeyValuesForm",
              config: {
                label: i18n.t("valueField"),
                formAttribute: "dictionaryValues",
                deleteModal: {
                  title: i18n.t("deleteValueTitle"),
                  question: i18n.t("deleteValueQuestion"),
                  primaryButtonText: i18n.t("ok"),
                  secondaryButtonText: i18n.t("cancel"),
                  alerts: [
                    {
                      text: i18n.t("deleteValueAlert"),
                      type: "warning",
                      icon: "mdi-alert",
                    },
                  ],
                },
              },
            },
          ],
        },
      };
    },
    DictionariesDetail() {
      return {
        editDictionary: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonEditDictionary(),
            title: i18n.t("editDictionary"),
            collectionAttr: "dictionaries",
            alertAttr: "theDictionary",
          },
          steps: [
            {
              title: i18n.t("indicateDictionaryName"),
              resume: [
                {
                  label: i18n.t("dictionaryName"),
                  valuekey: "name",
                },
              ],
              component: "InputText",
              config: {
                label: i18n.t("dictionaryName"),
                formAttribute: "name",
                validators: {
                  required: {msg: i18n.t("required")},
                  alreadyExists: {msg: i18n.t("nameAlreadyExists")},
                }
              },
            },
            {
              title: i18n.t("defineDictionaryKeys"),
              component: "DictionaryKeyValuesForm",
              resume: [
                {
                  label: "Key",
                  valuekey: "dictionaryKeys",
                  itemProp: "value"
                },
              ],
              config: {
                label: i18n.t("keyField"),
                formAttribute: "dictionaryKeys",
                staticAlerts: [
                  {
                    text: i18n.t("modifiedDictionaryAlert"),
                    type: "warning",
                    icon: "mdi-alert",
                  },
                ],
                deleteModal: {
                  title: i18n.t("deleteKeyTitle"),
                  question: i18n.t("deleteKeyQuestion"),
                  primaryButtonText: i18n.t("ok"),
                  secondaryButtonText: i18n.t("cancel"),
                  alerts: [
                    {
                      text: i18n.t("deleteKeyAlert"),
                      type: "warning",
                      icon: "mdi-alert",
                    },
                  ],
                },
              },
            },
            {
              title: i18n.t("defineDictionaryValues"),
              component: "DictionaryKeyValuesForm",
              config: {
                label: i18n.t("valueField"),
                formAttribute: "dictionaryValues",
                staticAlerts: [
                  {
                    text: i18n.t("modifiedDictionaryAlert"),
                    type: "warning",
                    icon: "mdi-alert",
                  },
                ],
                deleteModal: {
                  title: i18n.t("deleteValueTitle"),
                  question: i18n.t("deleteValueQuestion"),
                  primaryButtonText: i18n.t("ok"),
                  secondaryButtonText: i18n.t("cancel"),
                  alerts: [
                    {
                      text: i18n.t("deleteValueAlert"),
                      type: "warning",
                      icon: "mdi-alert",
                    },
                  ],
                },
              },
            },
          ],
        },
        cloneDictionary: {
          config: {
            secondaryButton: this.secondaryButton(),
            primaryButton: this.primaryButtonClone(),
            title: i18n.t("cloneDictionary"),
            collectionAttr: "dictionaries",
            alertAttr: "theDictionary",
          },
          steps: [
            {
              title: i18n.t("indicateDictionaryName"),
              resume: [
                {
                  label: i18n.t("dictionaryName"),
                  valuekey: "name",
                },
              ],
              component: "InputText",
              config: {
                label: i18n.t("dictionaryName"),
                formAttribute: "name",
                validators: {
                  required: {msg: i18n.t("required")},
                  alreadyExists: {msg: i18n.t("nameAlreadyExists")},
                }
              },
            },
            {
              title: i18n.t("defineDictionaryKeys"),
              component: "DictionaryKeyValuesForm",
              resume: [
                {
                  label: "Key",
                  valuekey: "dictionaryKeys",
                  itemProp: "value"
                },
              ],
              config: {
                label: i18n.t("keyField"),
                formAttribute: "dictionaryKeys",
                deleteModal: {
                  title: i18n.t("deleteKeyTitle"),
                  question: i18n.t("deleteKeyQuestion"),
                  primaryButtonText: i18n.t("ok"),
                  secondaryButtonText: i18n.t("cancel"),
                  alerts: [
                    {
                      text: i18n.t("deleteKeyAlert"),
                      type: "warning",
                      icon: "mdi-alert",
                    },
                  ],
                },
              },
            },
            {
              title: i18n.t("defineDictionaryValues"),
              component: "DictionaryKeyValuesForm",
              config: {
                label: i18n.t("valueField"),
                formAttribute: "dictionaryValues",
                deleteModal: {
                  title: i18n.t("deleteValueTitle"),
                  question: i18n.t("deleteValueQuestion"),
                  primaryButtonText: i18n.t("ok"),
                  secondaryButtonText: i18n.t("cancel"),
                  alerts: [
                    {
                      text: i18n.t("deleteValueAlert"),
                      type: "warning",
                      icon: "mdi-alert",
                    },
                  ],
                },
              },
            },
          ],
        },
      };
    },
  },
};
