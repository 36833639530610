<template>
  <div>
    <v-form :ref="'assignValue'" :validate="getStatusComponent">
      <v-autocomplete
        v-model="assignValue"
        hide-details
        clearable
        return-object
        :label="configAssignValues.label"
        :items="dataSourceAssignValues"
        :rules="[...configAssignValues.validators]"
        :item-text="'name'"
        :chips="false"
        :item-value="'value'"
        :class="'assignValuesInput'"
        @input="updateAssignValues($event)"
      ></v-autocomplete>
    </v-form>
    <component
      :key="assignValue?.component || assignValue?.value"
      :is="subComponentToShow"
      :globalValues="globalValues"
      :config="configSubComponentAssginValues"
      :currentDataValues="subComponentCurrentValues"
      :dataSource="dataSource"
      :submitFormComponent="submitFormComponent"
      :resetForm="resetForm"
      :getStatus="getStatusComponent"
      :parentComponent="parentComponent"
      @isValidForm="isValidFormSubComponent"
      @update="updatedSubComponent"
      @dirty="dirtySubComponent"
    />  
  </div>
</template>

<script>
import { tools } from "@/mixins/tools";
import i18n from "@/plugins/i18n";

export default {
  name: "AssignValues", 
  mixins: [tools],
  props: {
    parentComponent: {type: String},
    globalValues: { type: Array },
    currentDataValues: { type: Object },
    submitFormComponent: { type: Boolean },
    resetForm: { type: Boolean },
    dataSource: { type: Object },
    dataSourceWithoutConcatenate: { type: Boolean, default: false },
    getStatus: { type: Boolean },
    config: { type: Object, default: () => ({})},
    configSubComponent: { type: Object, default: () => (undefined)},
    referenceAttr: { type: String, default: 'columnReference' },
  },
  data: () => ({
    getStatusComponent: false,
    dirty: false,
    assignValue: null,
    subComponentToShow: undefined,
    assignValueDynamic: null,
    subComponentCurrentValues: null,
    defaultConfigOptions: {
      label: i18n.t("assignValue"),
      dataSourceAttr: 'assignValues',
      validators: [
        v => Boolean(v) || i18n.t("required")
      ]
    },
    valuesTransformation: {
      dictionaryTransformation: 'DictionaryForm',
      addTimeStepTransformation: 'TenorForm',
      joinColumns: 'ConcatenateValuesForm',
    },
    option: {
        INPUT_COLUMN_NAME: 'INPUT_COLUMN_NAME',
        OUTPUT_COLUMN_NAME: 'OUTPUT_COLUMN_NAME',
        INPUT_VALUE: 'FixedValueForm',
        WILDCARD: 'WildcardForm',
        VARIABLE: 'ApplyVarForm',
      },
  }),
  computed: {
    configSubComponentAssginValues() {
      return {...this.configSubComponent, assignValue: this.assignValue?.value };
    },
    configAssignValues() {
      return {...this.defaultConfigOptions, ...this.config};
    },
    dataSourceAttr() {
      return this.configAssignValues.dataSourceAttr || [];
    },
    dataSourceAssignValues() {
      return this.dataSource[this.dataSourceAttr].filter(({hiddenWhenParentIs = []}) => {
        return !hiddenWhenParentIs.includes(this.parentComponent);
      });
    }
  },
  methods: {
    setDirty(event) {
      if (!this.dirty && event.dirty !== this.dirty){
        this.dirty = event.dirty;
      }
    },
    formSubmitted(event) {
      this.setDirty(event);
      this.$nextTick(() => {
        this.getStatusComponent = false;
      });
    },

    updateAssignValues(event = null, fromCreated = false) {
      if (!event) {
        const isValid = !this.validators?.length;
        this.$emit("update", null);
        this.$emit("isValidForm", { valid: isValid })
      }

      if (!fromCreated) {
        this.dirty = true;
      }
      this.subComponentCurrentValues = null;
    },
    updatedSubComponent(event) {
      this.$nextTick(() => {
        this.subComponentCurrentValues = event;
        this.$emit("update", event);
      });
    },
    isValidFormSubComponent(event) {
      this.$emit("isValidForm", event);
    },
    dirtySubComponent(event) {
      if (!this.dirty && event) {
        this.dirty = event;
      }
    },
    setAssignValue(currentValues) {
      if (!currentValues || !Object.keys(currentValues).length) return null;
      let containedKey;
      const recursiveSearch = (objToSearchIn, keyToSearchFor) => {
        return Object.keys(objToSearchIn).some(key => {
          const value = objToSearchIn[key];
          if (key === keyToSearchFor) {
            containedKey = key;
            return key
          } else if ( value && typeof value === 'object') {
            recursiveSearch(value, keyToSearchFor);
          }
        });
      };
      let toIterate = [...Object.keys(this.valuesTransformation), this.referenceAttr];

      [...toIterate].some(key => {
        return recursiveSearch(currentValues, key)
      })

      if (!containedKey) return undefined;
      const { option } = currentValues[containedKey] || {};

      return this.dataSourceAssignValues.find(({ value }) => !option ? value === this.valuesTransformation[containedKey]: value === this.option[option]);
    },
  },
  watch: {
    assignValue: {
      deep: true,
      handler(val) {
        if (!val) {
          this.subComponentToShow =  undefined;
          return;
        }
        const name = val.component || val.value
        this.subComponentToShow = () => import(`../stepperComponents/${name}.vue`)
      }
    },
    submitFormComponent(val) {
      if (val) {
        this.getStatusComponent = val;
        this.$refs.assignValue.validate();
      } else {
        this.$nextTick(() => {
          this.getStatusComponent = val;
        });
      }
    },
    getStatus(val) {
      this.getStatusComponent = val;
    },
    dirty(val) {
      this.$emit("dirty", val);
    },
  },
  created () {
    if (this.currentDataValues) {
      this.subComponentCurrentValues = this.deepCopyFunction(this.currentDataValues);
      this.assignValue = this.setAssignValue(this.subComponentCurrentValues);
    } else {
      this.updateAssignValues(null, true);
    }
  }
};
</script>

<style lang="scss" scoped>
.assignValuesInput {
  margin-bottom: 30px;
}
</style>
