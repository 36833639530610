import i18n from "@/plugins/i18n";

export default {
  computed: {
    fields() {
      return {
        dateFormat: [
          "dd/MM/yyyy", "dd/MM/yy", "yyyy/MM/dd", "yy/MM/dd", "yyyy/MM/dd HH-mm:ss", "dd/MM/yyyy HH-mm:ss",
          "dd-MM-yyyy", "dd-MM-yy", "yyyy-MM-dd", "yy-MM-dd", "yyyy-MM-dd HH-mm:ss", "dd-MM-yyyy HH-mm:ss", "yyyy-MM-ddTHH:mm:ss.sZ"
        ],
        termsType: [
          { value: "columnAlias", name: i18n.t("columnAlias") },
          { value: "fixedValue", name: i18n.t("fixedValue") },
          { value: "wildcard", name: i18n.t("wildcard") }
        ],
        termsTypeColumn: [
          { value: "inputColumnName", name: i18n.t("inputColumnName") },
          { value: "fixedValue", name: i18n.t("fixedValue") },
          { value: "wildcard", name: i18n.t("wildcard") }
        ],
        termsTypeNotOkay: [
          {name: i18n.t("columnAlias"), value: "columnAliasNotOk"},
          {name: i18n.t("fixedValue"), value: "fixedValueNotOk"},
          {name: i18n.t("wildcard"), value: "wildcardNotOk"}
        ],
        filterConditionals: [
          { value: 'EQUAL_TO', name: '='},
          { value: 'NOT_EQUAL', name: '!='},
          { value: 'GREATER_THAN', name: '>'},
          { value: 'LESS_THAN', name: '<'},
          { value: 'GREATER_THAN_OR_EQUAL_TO', name: '>='},
          { value: 'LESS_THAN_OR_EQUAL_TO', name: '<='},
          { value: 'EMPTY', name: i18n.t('EMPTY')},
          { value: 'NOT_EMPTY', name: i18n.t('NOT_EMPTY')},
          { value: 'REGULAR_EXPRESSION', name: i18n.t("REGULAR_EXPRESSION")},
        ],
        wildcardsToday: [
          { value: 'today', name: 'today' },
        ],
        wildcards:[
          { value: 'today', name: 'today' },
          { value: 'today.day', name: 'today.day' },
          { value: 'today.month', name: 'today.month' },
          { value: 'today.year', name: 'today.year' },
          { value: 'identifier', name: 'identifier' }
        ],
        wildcardsDictionary:[
          { value: '{today}', name: '{today}' },
          { value: '{today.day}', name: '{today.day}' },
          { value: '{today.month}', name: '{today.month}' },
          { value: '{today.year}', name: '{today.year}' },
          { value: '{identifier}', name: '{identifier}' },
        ],
        wildcardsOther:[
          { value: '{today}', name: '{today}' },
          { value: '{today.day}', name: '{today.day}' },
          { value: '{today.month}', name: '{today.month}' },
          { value: '{today.year}', name: '{today.year}' },
          { value: '{identifier}', name: '{identifier}' },
          { value: '{other}', name: '{other}' }
        ],
        defaultDecimalSeparator: [
          { value: "COMMA", name: i18n.t("comma") },
          { value: "DOT", name: i18n.t("dot") },
        ],
        fileType: [{ value: "CSV", name: "csv" }],
        dataType: [
          { value: "INTEGER", name: "Integer" },
          { value: "DOUBLE", name: "Double" },
          { value: "DATE", name: "Date" },
          { value: "STRING", name: "String" },
          { value: "BOOLEAN", name: "Boolean" },
          { value: "PERCENTAGE", name: "Percentage" },
          { value: "MAIL", name: "Mail" },
          { value: "TENOR", name: "Tenor" }
        ],
        validationOptionalType: [
          { value: "range", name: i18n.t("range") },
          { value: "allowedValuesValidation", name: i18n.t("allowedValuesValidation") },
        ],
        rangeType: [
          { value: "CLOSE", name: i18n.t("CLOSE") },
          { value: "OPEN", name: i18n.t("OPEN") },
          { value: "OPEN_CLOSE", name: i18n.t("OPEN_CLOSE") },
          { value: "CLOSE_OPEN", name: i18n.t("CLOSE_OPEN") },
        ],
        transformationType: [
          { value: "DICTIONARY", name: i18n.t("dictionary") },
          { value: "ADD_TIME_STEP", name: i18n.t("addTenor") },
        ],
        tenor: ["D", "W", "M", "Y"],
        inputDefinitionsData: {
          fromVuex: true,
          variable: "inputDefinitionsData"
        },
        filtersFromInputDefinition: {
          fromVuex: true,
          variable: "inputDefinitionsData",
          atribute: 'filters',
          findElementOnVuex: {
            variable: 'value',
          }
        },
        outputDefinitionsData: {
          fromVuex: true,
          variable: 'outputDefinitionsData'
        },
        dictionariesData: {
          fromVuex: true,
          variable: "dictionariesData"
        },
        columnsReferenced: {
          fromVuex: true,
          variable: "inputDefinitionsData",
          atribute: "columns",
          transformDataColumnsDataSource: true,
          findElementOnVuex: {
            variable: "inputDescriptorReference",
            atribute: "value"
          }
        },
        outputColumnsReferenced: {
          fromVuex: true,
          variable: "outputDefinitionsData",
          atribute: "columns",
          transformDataColumnsDataSource: true,
          findElementOnVuex: {
            variable: "name",
          }
        },
        columnsReferencedForFilters: {
          fromVuex: true,
          variable: "inputDefinitionsData",
          atribute: "columns",
          transformDataColumnsDataSource: true,
          findElementOnVuex: {
            variable: "name",
          }
        },
        columnsReferencedTenor: {
          fromVuex: true,
          variable: "inputDefinitionsData",
          atribute: "columns",
          transformDataColumnsDataSource: true,
          findElementOnVuex: {
            variable: "inputDescriptorReference",
            atribute: "value",
            filter: {
              dataType: "TENOR"
            }
          }
        },
        columnsReferencedDate: {
          fromVuex: true,
          variable: "inputDefinitionsData",
          atribute: "columns",
          transformDataColumnsDataSource: true,
          findElementOnVuex: {
            variable: "inputDescriptorReference",
            atribute: "value",
            filter: {
              dataType: "DATE"
            }
          }
        },
        columnsReferencedDouble: {
          fromVuex: true,
          variable: "inputDefinitionsData",
          atribute: "columns",
          transformDataColumnsDataSource: true,
          findElementOnVuex: {
            variable: "inputDescriptorReference",
            atribute: "value",
            filter: {
              dataType: "DOUBLE"
            }
          }
        },
        columnsReferencedPercentage: {
          fromVuex: true,
          variable: "inputDefinitionsData",
          atribute: "columns",
          transformDataColumnsDataSource: true,
          findElementOnVuex: {
            variable: "inputDescriptorReference",
            atribute: "value",
            filter: {
              dataType: "PERCENTAGE"
            }
          }
        },
        operators: [
          { value: "ADD", name: "+" },
          { value: "SUBTRACT", name: "-"}
        ],
        assignValues: [
          { value: "INPUT_COLUMN_NAME", name: i18n.t("columnReferenceInput"), component: 'ColumnReferenceForm'},
          { value: "OUTPUT_COLUMN_NAME", name: i18n.t("columnReferenceOutput"), component: 'ColumnReferenceForm',  hiddenWhenParentIs: ['ConditionFiltersForm']},
          { value: "FixedValueForm", name: i18n.t("fixedValue") },
          { value: "WildcardForm", name: i18n.t("wildcard") },
          { value: "ApplyVarForm", name: i18n.t("applyVar") },
          { value: "DictionaryForm", name: i18n.t("dictionary"), hiddenWhenParentIs: ['TenorForm', 'ConditionForm', 'ConditionFiltersForm'] },
          { value: "TenorForm", name: i18n.t("addTenor"), hiddenWhenParentIs: ['TenorForm', 'ConditionForm', 'ConditionFiltersForm' ] },
          { value: "ConcatenateValuesForm", name: i18n.t("concatenateValues"), hiddenWhenParentIs: ['ConcatenateValuesForm', "TenorForm", 'ConditionForm', 'ConditionFiltersForm'] }
        ],
        delimiter: [
          { value: "COMMA", name: i18n.t("COMMA") },
          { value: "SEMICOLON", name: i18n.t("SEMICOLON") },
          { value: "TAB", name: i18n.t("TAB") },
          { value: "SPACE", name: i18n.t("SPACE") },
          { value: "PIPE", name: i18n.t("PIPE") },
        ],
      };
    },
  },
};
