import i18n from "@/plugins/i18n";

export default {
  computed: {
    stepperConfig() {
      return {
        title: i18n.t("runProcess"),
      }
    },
    steps () {
      return [
        {
          title: i18n.t("selectFileToTransform"),
          component: "ImportFile",
          useFileToUploadForResume: true,
        },
        {
          title: i18n.t("columnSeparatorStep"),
          component: "AutocompleteComponent",
          config: {
            formAttribute: 'delimiter',
            dataSourceAttr: 'delimiter',
          },    
          resume: [
            {
              label: i18n.t("delimiterLabel"),
              valuekey: 'delimiter',
              translateValue: true,
            },
          ],
        },
        {
          title: i18n.t("selectInputDefinition"),
          component: "AutocompleteComponent",
          resume: [
            {
              label: i18n.t("inputDescriptorInfoLabel"),
              key: "inputDescriptorInfo",
              valuekey: 'value',
            },
          ],
          config: {
            formAttribute: 'inputDescriptorInfo',
            dataSourceAttr: 'inputDefinitionsData',
            noDataText: "noDataSelects.definitions",
          },
        },
        {
          title: i18n.t("selectFilters"),
          component: 'WithOrWithoutFilters',
          config: {
            requiredValidation: true,
            dependsOnDataSource: true,
            usePreviousStepData: 'inputDescriptorInfo',
            noDataText: "noDataSelects.filters",
          },
          resume: [
            {
              label: i18n.t("filters"),
              valuekey: "selectedFilterNames",
              chips: true,
              showWhenNull: i18n.t("withoutFilters"),
            }
          ]
        },
        {
          title: i18n.t("selectOneOrMoreOutputDefinition"),
          component: 'MultipleSelection',
          config: {
            inputLabel: "OutputDefinitions",
            inputAttr: 'outputDescriptorInfo',
            infoText: "selectOutputDefinitions",
            dataSourceAttr: 'outputDefinitionsData',
            dataSourceIsObj: true,
            plusText: "definitions",
            noDataText: "noDataSelects.definitions",
          },
          resume: [
            {
              label: i18n.t("OutputDefinitions"),
              key: "outputDescriptorInfo",
              valuekey: 'value',
              chips: true,
            },
          ],
        },
        {
          title: i18n.t("defineVariables"),
          component: "InputVariablesArrayForm",
          dependsOnPreviousStep: true,
        },
      ]
    }
  },
};
