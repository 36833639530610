<template>
  <div class="WithOrWithoutConditions">
    <v-form ref="form">
      <v-radio-group @change="update" :value="currentValues">
        <v-radio v-for="option of fieldsWithOrWithoutConditions.dataSource" :label="option.name" :value="option.value"
          :key="option.name" />
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>

import i18n from "@/plugins/i18n";

export default {
  name: "WithOrWithoutConditions",
  data: () => ({
    dirty: false,
    currentValues: undefined,
    getStatusComponent: false,
  }),
  props: {
    globalValues: { type: Array },
    currentDataValues: { type: Object },
    resetForm: { type: Boolean },
    getStatus: { type: Boolean },
    submitFormComponent: { type: Boolean },
  },
  methods: {
    update(event) {
      if (!this.dirty) this.dirty = true;
      this.$emit("isValidForm", { valid: this.validateForm() });
      this.currentValues = event;
    },
    validateForm() {
      if (this.$refs?.form) return this.$refs.form.validate();
    },
    emitIsValidForm() {
      this.$emit("isValidForm", { valid: this.validateForm() });
    },
  },
  computed: {
    fieldsWithOrWithoutConditions() {
      return {
        name: "withOrWithoutConditions",
        dataSource: [
          { value: "withOutConditions", name: i18n.t("withOutConditions") },
          { value: "withConditions", name: i18n.t("withConditions") },
        ],
        optionsToCheck: [
          { field: "withOutConditions", value: "withOutConditions" },
          { field: "withConditions", value: "withConditions" },
        ],
      };
    },
  },
  watch: {
    getStatus(v) {
      this.getStatusComponent = v;
    },
    resetForm(v) {
      if (v) this.currentValues = "withOutConditions";
    },
    submitFormComponent(v) {
      this.getStatusComponent = v;
    },
    currentValues(v) {
      this.$emit("update", { withOrWithoutConditions: v });
      this.emitIsValidForm();
    },
    dirty(v) {
      this.$emit("dirty", v);
    },
    getStatusComponent() {
      this.$emit("dirty", this.dirty);
      this.emitIsValidForm();
    }
  },
  mounted() {
    const { conditionals, withOrWithoutConditions } = this.currentDataValues || {};
    this.currentValues = (conditionals || withOrWithoutConditions === 'withConditions')
      ? 'withConditions'
      : 'withOutConditions'
  }
};
</script>

<style lang="scss" scoped>
.WithOrWithoutConditions {
  padding-bottom: 10px;
}
</style>