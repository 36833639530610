<template>
  <div>
    <Card :class="{ 'card-container': !isModal }" v-if="!emptyDictionary() || loadingData || loadingParent">
      <CardHeader class="card-header pa-5">
        <span class="card-title"> {{ traduceItem('dictionaryDetail') }} </span>
        <span>
          <Actions v-if="dictionaryActions && dictionaryActions.length" :actions="dictionaryActions"
            @action-selected="onActionSelected($event)" />
        </span>
      </CardHeader>
      <CardContent class="card-content-table">
        <DataTableSkeleton v-if="loadingData || loadingParent" class="ma-5 mt-10 mb-5" />
        <DataTable v-else ref="tableChild" class="ma-5 mt-10 mb-5" :headers="dataTableHeaders" :items="dataTable"
          :showActions="true" :actions="actionsDataTable" :hoverColor="hoverColor"
          :confirmationModal="confirmationActionTable" :isModal="isModal" @update-table="updateTable">

          <template v-slot:item.status="{ editedItem, item }">
            <v-tooltip bottom :max-width="200" v-if="editedItem.uuid !== item.uuid && isColKeyError('status', item)">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="error">mdi-alert-circle</v-icon>
              </template>
              <span>{{ traduceItem("VALIDATION_DUPLICATED_KEYS_TOOLTIP") }}</span>
            </v-tooltip>
          </template>
          <template v-for="(col, i) in columns" v-slot:[`item.${col}`]="{ editedItem, item }">
            <div class="cell-container" :key="i" :class="getBorderBody(col)">
              <div class="combobox-container" v-if="editedItem.uuid === item.uuid">
                <div class="combobox">
                  <v-combobox v-model="editedItem[col]" :items="wildcards(col)" :item-text="'name'"
                    :item-value="'value'" :error="isColKeyError(col, editedItem)"
                    :class="{ 'error-combobox': isColKeyError(col, editedItem) }"
                    @input.native="editedItem[col] = $event.srcElement.value; isAnyRowDuplicated()" :height="'18px'"
                    :hide-details="true"></v-combobox>
                </div>
                <div class="error-icon" v-if="isColKeyError(col, editedItem)">
                  <v-tooltip bottom :max-width="200">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="error">mdi-alert-circle</v-icon>
                    </template>
                    <span>{{ traduceItem("VALIDATION_DUPLICATED_KEYS_TOOLTIP") }}</span>
                  </v-tooltip>
                </div>
              </div>
              <div class="text-container" v-else>
                <div class="text">
                  {{ item[col] && item[col].length > 0 ? getComboData(item[col]) : '--' }}
                </div>
              </div>
            </div>
          </template>
        </DataTable>
      </CardContent>
    </Card>

    <div v-else>
      <NoData class="noData" :cardHeaderText="traduceItem('dictionaryDetail')" :text="configNodata.text"
        :image="configNodata.image"></NoData>
    </div>

    <v-dialog :value="dictionaryToMaximize" @click:outside="closeModal">
      <DetailDictionary :isModal="true" @close-modal="closeModal"></DetailDictionary>
    </v-dialog>
  </div>
</template>

<script>

import { firebaseTools } from "@/mixins/firebase-tools";
import { tools } from "@/mixins/tools";
import i18n from "@/plugins/i18n";
import { mapActions, mapState } from "vuex";
import NoData from "../views/common/NoData.vue";
import DataTableSkeleton from "../skeletonComponents/DataTableSkeleton.vue";

export default {
  name: "DetailDictionary",
  mixins: [firebaseTools, tools],
  components: {
    NoData,
    DataTableSkeleton
  },
  props: {
    /** The actions displayed on the table */
    isModal: { type: Boolean, default: false },
    loadingParent: { type: Boolean, default: false },
  },
  data: () => ({
    actionsDataTable: [
      {
        action: 'edit',
        label: i18n.t('edit'),
        class: 'active',
        icon: "mdi-pencil",
      },
      {
        action: 'delete',
        label: i18n.t('delete'),
        class: 'active',
        icon: "mdi-delete",
      },
    ],
    hoverColor: '#f8f2fc',
    confirmationActionTable: {
      title: i18n.t('deleteDictionaryKeyValueTitle'),
      question: i18n.t('deleteDictionaryKeyValueQuestion'),
      primaryButtonText: i18n.t('ok'),
      secondaryButtonText: i18n.t('cancel'),
    },
    dictionaryToMaximize: undefined,
    modalConfig: {
      title: i18n.t('dictionaryDetail')
    },
    showingSnackBarError: false,
    dataSource: [],
  }),
  methods: {
    ...mapActions([
      "setDataView",
      "setLoadingData",
      "setItemToConfirmAction",
    ]),
    updateTable(event) {
      const actualView = this.actualView.parent || this.actualView;
      const documentId = this.$route.params.id;
      const collectionName = actualView.get[0].collection;
      const result = this.constructResult(event.data);

      if (event.action === 'delete-all') {
        this.setItemToConfirmAction({
          title: i18n.t('deleteDictionarySettingTitle'),
          text: i18n.t('deleteDictionarySettingQuestion'),
          actualView: actualView,
          data: {
            deleteNullValues: true,
            params: {
              action: 'edit',
              element: result,
              collectionName: collectionName,
              documentId: documentId,
            },
            method: "updateDocument",
          }
        });

      } else if (event.action === 'delete' || !this.isAnyRowDuplicated()) {
        const action = event.action === 'delete' ? 'deleteKeyValue' : this.$refs.tableChild.editingNewItem() ? 'addKeyValue' : 'updateKeyValue';
        this.updateDocument(collectionName, documentId, result, action, null, true).then(() => {
          if (event.action === 'update' && this.$refs.tableChild) {
            this.$refs.tableChild.close(true);
          }
        });
      }
    },

    constructResult(table) {
      const result = [];
      const firstRow = (this.dataView.dictionaryValues[0].row || []);
      table.forEach(item => result.push(this.getRowData(firstRow, item)));
      return { userGroup: this.dataView.userGroup, name: this.dataView.name, dictionaryValues: result };
    },

    getRowData(firstRow, item) {
      delete item.uuid;
      if (item.row) return item;

      const row = { row: [] };
      Object.keys(item.row ? item.row : item).forEach(prop => {
        const cellIsKey = firstRow.find(row => row.entry.key === prop).cellIsKey;
        row.row.push({ cellIsKey: cellIsKey, entry: { key: prop, value: this.getComboData(item[prop]) } });
      });
      return row;
    },

    defaultTableHeaders() {
      return [
        { text: 'Keys', value: 'keys', children: [] },
        { text: 'Values', value: 'values', children: [] },
      ]
    },

    getComboData(item) {
      if (item === undefined || item === null) return null;

      if (typeof item === 'object') {
        return item.value;
      }
      return item;
    },

    onActionSelected(event) {
      switch (event.action) {
        case 'deleteDictionaryContent':
          this.updateTable({ data: [], action: 'delete-all' });
          if (this.isModal) {
            this.$emit('close-modal');
          }
          break;
        case 'addKeyValue':
          this.addRow();
          break;
        case 'maximizeDictionaryTable':
          this.dictionaryToMaximize = { config: this.modalConfig };
          break;
        case 'closeDictionaryModal':
          this.$emit('close-modal');
          break;
      }
    },
    addRow() {
      if (!this.dataView.dictionaryValues || this.dataView.dictionaryValues.length == 0) return;
      this.$refs.tableChild.addNew(Object.assign({}, this.defaultItem));
      this.$nextTick(() => {
        this.isAnyRowDuplicated();
      });
    },
    isColKeyError(col, itemTable) {
      const headerKeys = [...this.dataTableHeaders[0].children.map(item => item.value)];
      if (col !== 'status' && headerKeys.find(item => item === col) === undefined) return false;

      const dataTable = this.$refs.tableChild ? this.$refs.tableChild.currentItems() : [];
      const duplicatedValuesArray = this.getDuplicates(headerKeys, dataTable);

      if (duplicatedValuesArray.length === 0) {
        this.hoverColor = '#f8f2fc';
        this.showingSnackBarError = false;
      } else {
        if (!this.showingSnackBarError) {
          this.showingSnackBarError = true;
          this.setShowSnackBar({
            color: 'error',
            icon: "mdi-alert-circle",
            msg: i18n.t('VALIDATION_DUPLICATED_KEYS_SNACKBAR'),
          });
          this.hoverColor = '#fbeded';
        }
        if (duplicatedValuesArray.find(uuid => uuid === itemTable.uuid) !== undefined) {
          return true;
        }
      }
      return false;
    },
    isAnyRowDuplicated() {
      const dataTable = this.$refs.tableChild ? this.$refs.tableChild.currentItems() : [];
      const headerKeys = this.dataTableHeaders[0].children.map(item => item.value);

      const duplicatedValuesArray = this.getDuplicates(headerKeys, dataTable);

      if (duplicatedValuesArray.length === 0) {
        this.hoverColor = '#f8f2fc';
        this.showingSnackBarError = false;
        return false;
      } else {
        if (!this.showingSnackBarError) {
          this.showingSnackBarError = true;
          this.setShowSnackBar({
            color: 'error',
            icon: "mdi-alert-circle",
            msg: i18n.t('VALIDATION_DUPLICATED_KEYS_SNACKBAR'),
          });
          this.hoverColor = '#fbeded';
        }
        return true;
      }
    },
    wildcards(col) {
      if (!this.dataSource) {
        this.dataSource = this.getDataSource();
        return this.wildcards(col);
      }
      if (!this.dataSource || col === undefined) return [];

      const firstRow = (this.dataView.dictionaryValues[0].row || []);
      const cellIsKey = firstRow.find(row => row.entry.key === col).cellIsKey;
      if (cellIsKey) return this.dataSource.wildcardsOther || [];
      return this.dataSource.wildcardsDictionary || [];

    },
    getBorderBody(col) {
      const headers = this.dataTableHeaders;
      const parentHeaderIndex = headers.findIndex(item => item.children.findIndex(child => child.value === col) !== -1);
      if (parentHeaderIndex !== 0) {
        if (headers[parentHeaderIndex].children.findIndex(child => child.value === col) === 0) {
          return 'td-border-style';
        }
      }
      return '';
    },
    emptyDictionary() {
      return !this.dataView || !this.dataView.dictionaryValues || this.dataView.dictionaryValues.length === 0;
    },
    getDuplicates(headersKeys, data) {
      const map = new Map();

      if (!data) return map;

      data.forEach((item) => {
        const rowValues = headersKeys.map(key => this.getComboData(item[key]));
        const rowString = JSON.stringify(rowValues).toLowerCase();
        if (map.has(rowString)) {
          map.get(rowString).push(item.uuid)
        } else {
          map.set(rowString, [item.uuid]);
        }
      });

      const result = [];

      Array.from(map.values()).filter((value) => value.length > 1).forEach((value) => {
        result.push(...value);
      })

      return result;
    },
    closeModal() {
      this.dictionaryToMaximize = undefined;
      if (this.$refs.tableChild) {
        this.$refs.tableChild.setItems([...this.dataTable])
      }
    },

  },
  computed: {
    ...mapState([
      "dataView",
      "actualView",
      "envTheme",
      "dictionariesData",
      "listsData",
      "loadingData",
    ]),
    dataTable() {
      if (this.emptyDictionary()) return [];
      const data = this.dataView.dictionaryValues.map((item) => {
        let result = {};
        (item.row || []).map(element => element.entry).forEach(element => {
          result[element.key] = element.value;
        });
        return result;
      });
      return data || [];
    },
    dictionaryActions() {
      return this.setActions(this.actualView?.componentActions
        .filter(option => option.showWhen === "always" || (this.isModal ? option.showWhen === "modal" : option.showWhen === "notModal"))
        .map(option => {
          if (option.action === 'addKeyValue') {
            option.disabled = this.$refs.tableChild ? this.$refs.tableChild.editingNewItem() : false;
          }
          return option
        }));
    },
    dataTableHeaders() {
      let headers = this.defaultTableHeaders();
      if (this.emptyDictionary()) return headers;
      const firstRow = (this.dataView.dictionaryValues[0].row || []);


      firstRow.forEach(row => {
        const indexHeader = row.cellIsKey ? 0 : 1
        headers[indexHeader].children.push({ text: row.entry.key, value: row.entry.key });
      })
      return headers;
    },
    columns() {
      if (this.emptyDictionary()) return [];
      const firstRow = (this.dataView.dictionaryValues[0].row || []);
      return firstRow.map(row => row.entry.key);
    },

    configNodata() {
      return {
        text: i18n.t(
          !this.searching ? `noData.${this.actualView.name}${this.isDependsOnActionHidden ? 'NoActions' : ''}` : "noSearchData"
        ),
        image: !this.searching ? "noData" : "search",
      };
    },

    defaultItem() {
      if (this.emptyDictionary()) return {};
      const defaultItem = {};
      (this.dataView.dictionaryValues[0].row || []).map(element => element.entry)
        .forEach(element => {
          defaultItem[element.key] = '';
        });
      return defaultItem;
    }

  },
  created() {
    this.dataSource = this.getDataSource()
  }
};
</script>

<style lang="scss" scoped>
.card-container {
  margin-top: 20px;
}

.card-content {
  padding: 0px !important;
}

::v-deep .v-icon.v-icon {
  font-size: 20px;
}

::v-deep .card-header-content {
  display: flex;
  justify-content: space-between;
}

::v-deep .card-header {
  padding-top: 20px !important;
}

.v-application .mt-10 {
  margin-top: 0px !important;
}

.v-card.v-sheet {
  padding-bottom: 0.1px;
}

::v-deep td:has(> .td-border-style) {
  border-left: 1px solid #ccc;
}

::v-deep th.child-header {
  white-space: nowrap;
}

::v-deep td .action-edit-icons .v-icon {
  color: var(--font);
}

.combobox-container {
  display: flex;
  align-items: center;

  .combobox {
    height: 48px;
  }

  .error-icon {
    margin-left: 5px;
    width: 5%;
  }

  .v-input {
    font-size: 14px !important;
  }
}

::v-deep .error-combobox .v-input__control .v-input__slot .v-select__slot input {
  color: var(--red);
}

.text-container {
  display: flex;
  color: var(--fontColor);
}

.card-title {
  font-size: 18px;
}

::v-deep .card-content .data-table-no-modal {
  max-height: calc(100vh - 295px);
}

::v-deep .card-content .data-table-modal {
  max-height: calc(100vh - 214px);
}

::v-deep .v-dialog {
  border-radius: 10px;
}

::v-deep td:has(.cell-container) {
  min-width: 300px;
  width: 300px;
  overflow-wrap: anywhere;
}

::v-deep .table-actions {
  width: 110px;
}

.noData {
  font-size: 18px;
}
</style>